.tick {
  font-size:1rem; white-space:nowrap; font-family:arial,sans-serif;
}

.tick-flip,.tick-text-inline {
  font-size:2.5em;
}

.tick-label {
  margin-top:1em;font-size:1em;
}

.tick-char {
  width:1.5em;
}

.tick-text-inline {
  display:inline-block;text-align:center;min-width:1em;
}

.tick-text-inline+.tick-text-inline {
  margin-left:-.325em;
}

.tick-group {
  margin:0 .5em;text-align:center;
}

.tick-text-inline {
   color: #595d63 !important;
}

.tick-label {
   color: #595d63 !important;
}

.tick-flip-panel {
   color: #fff !important;
}

.tick-flip {
   font-family: 'Urbanist' !important;
}

.tick-flip-panel-text-wrapper {
   line-height: 1.45 !important;
}

.tick-flip-panel {
   background-color: #3c3e3c !important;
}

.tick-flip {
   border-radius:0.12em !important;
}
