body {
  margin: 0;
  font-family: "GFS Didot", serif;
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body-visible {
  overflow: visible !important;
  padding-right: 0 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: #8F8F8F;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(143, 143, 143, 0.9);
  cursor: pointer;
}
